<template>
  <CCard body-wrapper>
    <h1>
      Добавление 1С Фулфилмента
    </h1>
    <CForm
      class="mt-5"
      @submit.prevent="create"
    >
      <CCard>
        <CCardBody>
          <CInput
            label="Название"
            required
            :invalid-feedback="getScalarFieldError(createForm.errors, 'name')"
            :is-valid="isScalarFieldInvalid(createForm.errors, 'name') ? false : null"
            :value.sync="createForm.data.name"
          />
          <CInput
            label="Логин"
            required
            :invalid-feedback="getScalarFieldError(createForm.errors, 'login')"
            :is-valid="isScalarFieldInvalid(createForm.errors, 'login') ? false : null"
            :value.sync="createForm.data.login"
          />
          <CInput
            label="Пароль"
            required
            :type="hidePassword ? 'password' : null"
            :invalid-feedback="getScalarFieldError(createForm.errors, 'password')"
            :is-valid="isScalarFieldInvalid(createForm.errors, 'password') ? false : null"
            :value.sync="createForm.data.password"
          >
            <template #append>
              <CButton color="secondary" size="sm" v-on:click="hidePassword = !hidePassword">
                <CIcon :name="hidePassword ? 'cilLockLocked' : 'cilLockUnlocked'" />
              </CButton>
            </template>
          </CInput>
          <div class="mb-2">
            <CLink @click.prevent="showAdditionalSettings = !showAdditionalSettings">Показать доп. настройки</CLink>
          </div>
          <CCollapse :show="showAdditionalSettings">
            <CInput
              label="Адрес сервера"
              description="Рекомендуем не менять этот параметр"
              :invalid-feedback="getScalarFieldError(createForm.errors, 'serverAddress')"
              :is-valid="isScalarFieldInvalid(createForm.errors, 'serverAddress') ? false : null"
              :value.sync="createForm.data.serverAddress"
            />
            <div class="form-group">
              <label>Откуда начинать</label>
              <date-picker
                type="date"
                v-model="createForm.data.defaultFrom"
                :clearable="false"
                :disabled-date="dateDisabled"
              />
            </div>
          </CCollapse>
          <CRow>
            <CCol col="auto">
              <loading-button :loading="createForm.inProcessing" text="Добавить" />
            </CCol>
            <CCol
              class="p-0"
              col="auto"
            >
              <div class="text-right">
                <CButton
                  color="dark"
                  :to="{ name: 'admin.warehouses.index' }"
                >
                  Закрыть
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CElementCover
        v-if="isLoadingDraft"
        :opacity="0.8"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
        />
      </CElementCover>
    </CForm>
  </CCard>
</template>

<script>
import FormMixins from '@/mixins/form-mixins';
import Swal from 'sweetalert2';
import DatePicker from 'vue2-datepicker';
import LoadingButton from '@/components/loading-button';
import moment from 'moment';

export default {
  name: 'Create',
  mixins: [
    FormMixins,
  ],
  components: {
    DatePicker,
    LoadingButton
  },
  data() {
    return {
      createForm: {
        data: {
          login: null,
          password: null,
          name: null,
          serverAddress: null,
          defaultFrom: new Date()
        },
        errors: {},
        inProcessing: false,
      },
      hidePassword: true,
      showAdditionalSettings: false,
      isLoadingDraft: false,
    };
  },
  mounted() {
    this.loadDraft();
  },
  methods: {
    dateDisabled(dt) {
      return moment(dt).isAfter(new Date(), 'day');
    },
    async loadDraft() {
      try {
        this.isLoadingDraft = true;
        const {data} = await this.$axios.post('/api/one_s/settings/draft');
        data.defaultFrom = moment(data.defaultFrom).toDate();
        this.createForm.data = data;
      } catch(e) {
        console.error(e);
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так!',
        }).then(() => {
          this.$router.replace({name: 'admin.warehouses.index'});
        })
      } finally {
        this.isLoadingDraft = false;
      }
    },
    async create() {
      this.createForm.errors = {};

      this.createForm.inProcessing = true;
      try {
        const {data} = await this.$axios.post('/api/one_s/settings', this.createForm.data);

        await Swal.fire(
          'Успешно',
          'Склад добавлен',
          'success',
        );
        await this.$router.push({ name: 'admin.warehouses.show', params: { id: data.warehouseId } });
      } catch (e) {
        if (this.hasErrorsInResponse(e)) {
          this.createForm.errors = e.response.data

          this.$nextTick(() => {
            this.showBaseErrors();
          })
        }
        console.error(e);
      } finally {
        this.createForm.inProcessing = false;
      }
    }
  },
};
</script>

<style scoped>

</style>
